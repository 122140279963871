import React, { useEffect, useState } from "react";

import { FETCH_HOME_FEED_TRIPS } from "../../constants";
import { Link } from 'react-router-dom';
import { apiHelper } from "../../utils/apiHelper";
import styled from "styled-components";

const TopHostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 13px;
  background: white;
  border-radius: 10px;
`

const TopHostsName = styled.span`
display: inline-block;
font-size: 13px;
color: #395fff;
cursor: pointer;
`

const TopHostHeading = styled.h2`
  text-align: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const TopHosts = () => {

  const [topHosts, setTopHosts] = useState([]);

  // TODO: add a shimmer while loading posts
  useEffect(() => {
    apiHelper({
      url: FETCH_HOME_FEED_TRIPS
    })
      .then((response) => {
        setTopHosts(response.data);
      })
      .catch((error) => {
        // TODO: do a proper error handling error displaying to user
        // alert("something went wrong, we are looking into it");
      })
  }, []);

  return (
    <>
      <TopHostsContainer>
        <TopHostHeading>Top Hosts</TopHostHeading>    
        <StyledLink to={"/user/tej8318"}><TopHostsName>tej8318</TopHostsName></StyledLink>
        



      </TopHostsContainer>
    </>

  )
}


export default TopHosts;