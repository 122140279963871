import {
  Container,
  LeftSidebar,
  MainContent,
  RightSidebar,
} from "../../containers/ThreeColumnContainer";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FETCH_EVENT_BY_LOCATION_SEARCH } from "../../../constants";
import Navbar from "../../navigation/NavBar";
import TripCard from "../../ui/TripCard/TripCard";
import { apiHelper } from "../../../utils/apiHelper";
import styled from 'styled-components';

function SearchPage() {
  const [tripsList, setTripsList] = useState([]);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const query = searchParams.get('q');

  // useEffect(() => {
  //   if (!query) {
  //     navigate('/'); // Redirect to the home page if myParam is empty
  //   }
  // }, [query, navigate]);


  // TODO: add infinite loading support also in this
  useEffect(() => {
    const requestUrl = `${FETCH_EVENT_BY_LOCATION_SEARCH}?location=${query}&limit=50`;
    apiHelper({ url: requestUrl })
      .then((response) => {
        setTripsList(response.data);
      })
      .catch((error) => {
        // TODO: do proper error handling and error displaying to user
      });

  }, [query])

  const isWishListed = false;


  return (
    <>
      <Navbar />
      <Wrapper>
        <TopWidget>
          Showing Results for {query}
        </TopWidget>
      </Wrapper>

      <Container>
        <LeftSidebar>
        </LeftSidebar>
        <MainContent>
          {tripsList.map((trip, index) => {
            return <TripCard key={trip.id} tripDetails={trip} is_wishlisted={isWishListed} />;
          })}
        </MainContent>
        <RightSidebar>
        </RightSidebar>
      </Container>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* This ensures some space around the content */
`;


const TopWidget = styled.div`
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (max-width: 481px) {
    width: 90%;
  }
`;

export default SearchPage;
