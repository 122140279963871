import "./index.css";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import ComingSoon from "./components/launchPage/LaunchPage";
import CommunityDiscovery from "./components/pages/community/CommunityDiscovery";
import CommunityPage from "./components/pages/community/CommunityPage";
import GlobalStyle from "./constants/globalStyles";
// Page Components
import HomePage from "./components/pages/home/HomePage";
import IntroSection from "./components/onboarding/IntroductionScreen";
import JoinCommunities from "./components/onboarding/JoinCommunities";
import NotFound from "./components/pages/errors/NotFound";
import PrivacyPolicy from "./components/privacyPolicy";
import PrivateProfile from "./components/pages/user/PrivateProfile";
import ProtectedRoute from "./components/navigation/ProtectedRoute";
import { Provider } from "react-redux";
import PublicProfile from "./components/pages/user/PublicProfile";
import React from "react";
import ReactDOM from "react-dom/client";
import SearchPage from "./components/pages/search/SearchPage";
import TopHostsPage from "./components/pages/topHosts/TopHostsPage";
import TopPostsPage from "./components/pages/topPosts/TopPostsPage";
import TripDetails from "./components/pages/trip/TripDetails";
import UserProfile from "./components/UserProfile/UserProfile";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";

// Define routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <ComingSoon />,
  },
  {
    path: "/baigan",
    element: <ProtectedRoute element={<HomePage />} />,
  },
  {
    path: "/baigan/onboarding",
    element: <IntroSection />,
  },
  {
    path: "/baigan/joinCommunity",
    element: <JoinCommunities />,
  },
  {
    path : "/baigan/privacy-policy",
    element : <PrivacyPolicy />
  },
  {
    path: "/baigan/community/:community_slug",
    element: <CommunityPage />,
  },
  {
    path: "/baigan/communities",
    element: <CommunityDiscovery />,
  },

  {
    path: "/baigan/search",
    element: <SearchPage />,
  },
  {
    path: "/baigan/trip/:tripId",
    element: <TripDetails />,
  },
  {
    path: "/baigan/user/:username",
    element: <PublicProfile />,
  },
  {
    path: "/baigan/user",
    element: <PrivateProfile />,
  },
  {
    path: "/baigan/top-posts",
    element: <TopPostsPage />,
  },

  {
    path: "/baigan/top-hosts",
    element: <TopHostsPage />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

// Create root and render app
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <GlobalStyle />
      <RouterProvider router={router} />
    {/* </React.StrictMode> */}
  </Provider>
);

// Measure performance
reportWebVitals();

serviceWorkerRegistration.register();