import React from "react";
import { StyledLink } from "../ui/random";
import styled from "styled-components";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #ffffff;
  height: 100vh;

  div{
  display: flex;
  flex-direction: column;
  align-items: center;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
`;

const TextContainer = styled.div`
  text-align: center;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  letter-spacing: 0.01rem;
  font-family: math;
  margin-top: 0;
  font-weight: 900;
}
`;

const Highlight = styled.span`
  color: #9f7aea; /* Use your preferred highlight color */
`;

const Description = styled.p`
  font-size: 18px;
  color: #555;
  margin-bottom: 15px;
  text-align: center;
`;

const ExploreButton = styled.button`
  background-color: #9f7aea; /* Match with your highlight color */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 4rem;

  &:hover {
    background-color: #7e57c2; /* Slightly darker shade for hover effect */
  }
  margin-bottom: 1rem;
`;


const handleClick = () => {
  localStorage.setItem("introSeen", true);
}


const IntroSection = () => {
  return (
    <Section>
      <div>
        <Image src="https://images.pexels.com/photos/1117132/pexels-photo-1117132.jpeg?auto=compress&cs=tinysrgb&w=800" alt="Community gathering" />
        <TextContainer>
          <div>
            <Title>
              Introducing <Highlight>Soccal!</Highlight>
            </Title>
            <Description>
              Explore what's happening around you and connect with your community
              through genuine, offline experiences.
            </Description>
            <Description>
              No distractions, just real-life connections close to home.
            </Description>
            <Description>
              <Highlight>Soccal</Highlight> - your hub for discovering real events
              in your local area.
            </Description>
          </div>


        </TextContainer>
      </div>
      <StyledLink to="/baigan/joinCommunity" onClick={handleClick}>
        <ExploreButton>Let’s explore</ExploreButton>
      </StyledLink>
    </Section>
  );
};

export default IntroSection;
