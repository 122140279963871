import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

const Card = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  color: #d7dadc;
  min-width: 30rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  text-decoration: none; /* Remove default underline */
  
  @media(max-width: 556px){
    min-width: 20rem;
  }

`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 15px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const DirectionArrow = styled(FaArrowRight)`
  color: #2f4f4f;
  font-size: 14px; 
  margin-left:auto;
`;

const Name = styled.div`
    font-weight: bold;
    color: black;
`;

const Description = styled.div`
  font-size: 14px;
  color: #818384;
  margin-top: 5px;
  white-space: nowrap;   
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

const Stats = styled.div`
  font-size: 12px;
  color: #818384;
  margin-top: 5px;
`;

const CommunityListingCard = ({ community }) => {
  return (
    <Card to={"/baigan/community/" + community.url} key={community.id}>
      <Avatar src={community.image} alt={`${community.name} avatar`} />
      <Info>
        <Name>{community.name}</Name>
        <Description>{community.description}</Description>
        {community.followers > 0 ? <Stats>{community.followers} members</Stats> : null}
      </Info>
      <DirectionArrow></DirectionArrow>
    </Card>
  );
};

export default CommunityListingCard;
