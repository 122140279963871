import { FaSearch } from 'react-icons/fa';
import React from 'react';
import styled from 'styled-components';

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;


const SearchInput = styled.input`
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const SearchButton = styled.button`
  background-color: #007bff;
  border: none;
  padding: 10px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: #0056b3;
  }
  
  svg {
    color: white;
    font-size: 18px;
  }
`;

const SearchBar = ({ value, onChange, placeholder, onSubmit }) => {
  return (
    <SearchBarContainer>
      <SearchInput
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit();  // Call onSubmit when Enter is pressed
          }
        }}
      />
      <SearchButton onClick={onSubmit}> {/* Call onSubmit when button is clicked */}
        <FaSearch />
      </SearchButton>
    </SearchBarContainer>
  );
};

export default SearchBar;
