import { HamburgerMenu, SideDrawer } from "./HamBurger";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import LoginPopUp from "../login/LoginPopUp.jsx";
import logo from "./transparent_logo.png";
import styled from "styled-components";
import { useUserData } from "../../hooks/useUserData.js";

const Navbar = () => {
  const userData = useUserData();

  const [showPopup, setShowPopup] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLoginClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>

      {showPopup && <LoginPopUp onClose={handleClosePopup} />}

      <Nav>
        <LeftContainer>

          <HamburgerMenu onClick={toggleDrawer} />
          <SideDrawer show={drawerOpen} onClose={toggleDrawer} login={handleLoginClick} />

          <Link to="/baigan">
            <Logo>
              <LogoImg src={logo} alt="Logo" />
            </Logo>
          </Link>
        </LeftContainer>



        <RightContainer>
          {userData?.isLoggedIn ? (
            <>
              <Link to={"/baigan/user"}>
                <ProfileImage src={userData.imageUrl} alt="Profile" />
              </Link>
            </>
          ) : (
            <LoginButton onClick={handleLoginClick}>Login</LoginButton>
          )}
        </RightContainer>
      </Nav></>

  );
};

export default Navbar;

const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const Nav = styled.nav`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #ff69b4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 7vh;
  background-color: #e6e6e6;
  z-index: 10;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImg = styled.img`
  height: 40px;
  width: auto;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap : 1rem;
`;

const LoginButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;
