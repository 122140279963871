import { Link } from 'react-router-dom';
import React from 'react';
import { formatINR } from '../../../utils/prices';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  max-height: 21rem;
  background-color: white;
  @media (max-width: 871px) {
    width: 80%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  padding-bottom: 0;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const HostName = styled.div`
  font-size: 14px;
`;

const Location = styled.div`
  color: #888;
  font-size: 12px;
`;

const Content = styled.div`
  padding: 12px;
`;

const Title = styled.h2`
  font-size: 15px;
  margin: 0 0 5px 0;
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;

  &::before {
    content: '📅';
    margin-right: 8px;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;

`;

const Button = styled.button`
  width: 100%;
  padding: 10px 0;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #5753c9;
  }
`;

const TripCardTopPosts = ({ tripDetails }) => {
  const imageUrl = "https://images.pexels.com/photos/3379937/pexels-photo-3379937.jpeg?auto=compress&cs=tinysrgb&w=800";
  const pageUrl = "/baigan/trip/" + tripDetails.id;

  return (
    <StyledLink to={pageUrl}>
      <Card>
        <Header>
          <Info>
            <HostName>{tripDetails.host_username}</HostName>
            <Location>{tripDetails.city}, {tripDetails.state}</Location>
          </Info>
        </Header>
        <Content>
          <Title>{tripDetails.title}</Title>
          <Date>{tripDetails.date[0]}</Date>
          <Price>{formatINR(tripDetails.price)}</Price>
        </Content>
      </Card>
    </StyledLink>
  );
};

export default TripCardTopPosts;
