import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>Privacy Policy for Soccal</h1>

      <p>
        At Soccal, accessible from <a href="https://soccal.in">https://soccal.in</a>, one of our main priorities is the privacy of our users. This Privacy Policy document contains types of information that is collected and recorded by Soccal and how we use it.
      </p>

      <h2>Information We Collect</h2>
      <p>
        When you use our app, we collect the following information:
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
        </ul>
      </p>

      <h2>How We Collect Information</h2>
      <p>
        We collect the above information when you log in to the app through Google Login.
      </p>

      <h2>How We Use Your Information</h2>
      <p>
        The information we collect from you is used for:
        <ul>
          <li>Personalizing your user experience</li>
          <li>Improving our services</li>
        </ul>
      </p>

      <h2>Contact Information</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, feel free to contact us at: <a href="mailto:soccalofficial@gmail.com">soccalofficial@gmail.com</a>
      </p>

      <h2>Effective Date</h2>
      <p>This Privacy Policy is effective as of the date of publication and will remain in effect until further notice.</p>
    </div>
  );
};

export default PrivacyPolicy;
