// src/components/ComingSoon.js
import React from 'react';
import styled from 'styled-components';

const ComingSoon = () => {
  return (
    <Container>
      <Content>
        <Title>Soccal - Launching Soon</Title>
        <SubText>Discover events and connect</SubText>
      </Content>
    </Container>
  );
};

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000; /* Black background */
  color: white;
`;

const Content = styled.div`
  text-align: center;
  padding: 0 20px; /* Add padding for mobile devices */
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif; /* Custom font from Google Fonts */
  font-size: 64px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #ff6b6b, #f9ca24);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    font-size: 48px; /* Adjust font size for tablets */
  }

  @media (max-width: 480px) {
    font-size: 32px; /* Adjust font size for mobile */
  }
`;

const SubText = styled.p`
  font-size: 22px;
  color: #ffffff;
  opacity: 0.8;

  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    font-size: 18px; /* Adjust font size for tablets */
  }

  @media (max-width: 480px) {
    font-size: 16px; /* Adjust font size for mobile */
  }
`;

export default ComingSoon;
