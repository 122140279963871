import { faHome, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

// Styled components
const Nav = styled.nav`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 7vh;
  background-color: white;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  @media (max-width: 900px) {
    display: flex;
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: #007bff;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-bottom: 2px;
  color: grey;
`;

const LoginButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

// BottomBar Component
const BottomBar = () => {

  return (
    <Nav>
      <StyledLink to="/baigan">
        <Button>
          <Icon icon={faHome} />
          Home
        </Button>
      </StyledLink>
      <StyledLink to="/baigan/communities">
        <Button>
          <Icon icon={faSearch} />
          Explore
        </Button>
      </StyledLink>
      <StyledLink to="/baigan/user">
        <Button>
          <Icon icon={faUser} />
          Profile
        </Button>
      </StyledLink>
    </Nav>
  );
};

export default BottomBar;
