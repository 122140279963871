// src/components/ProfilePage.js

import {
  FETCH_ALL_COMMUNITIES,
  FETCH_FOLLOWED_COMMUNITIES,
  FETCH_HOME_FEED_TRIPS,
  FETCH_PRIVATE_USER_PROFILE,
  FETCH_PUBLIC_USER_PROFILE,
  FETCH_USER_WISHLISTED_TRIPS
} from '../../constants';
import React, { useEffect, useState } from 'react';

import BeHostCard from './BeHostCard';
import BottomBar from '../navigation/BottomBar';
import { CenterContentContainer } from '../containers/utils';
import CommunityListingCard from '../pages/community/CommunityListingCard';
import DateRangePicker from '../ui/date/DateRangePicker';
import FilterButtonComponent from '../FilterButton/FilterButton';
import Navbar from '../navigation/NavBar';
import SideBar from './SideBar';
import { StyledLink } from '../ui/random';
import { ToggleButton } from '../HomePage/buttons';
import TripCardMini from '../ui/TripCard/TripCardMini';
import { apiHelper } from '../../utils/apiHelper';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const NavBarPsuedo = styled.div`
  @media (max-width: 725px) {
    display: none;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: #fff;
  color: #000;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 1rem;
  column-gap: 1rem;

  @media (max-width: 725px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
    margin-bottom: 0;
  }
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #fff;

  @media (max-width: 682px) {
    width: 100px;
    height: 100px;
  }
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const UsernameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Username = styled.h1`
  font-size: 24px;
  margin: 0 10px 0 0;

  @media (max-width: 481px) {
    font-size: 16px;
  }
`;

const BioSection = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: #ffffd6;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 10px;

  @media (max-width: 542px) {
    padding: 5px;
    margin-bottom: 20px;
    margin-top: 5px;
    font-size: 10px;
  }
`;

const BioText = styled.p`
  font-size: 16px;
  color: #000;

  @media (max-width: 542px) {
    font-size: 12px;
  }
`;

const Button = styled.button`
  background-color: #bebebe;
  color: #000;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #444;
  }
`;

const Stats = styled.div`
  display: flex;
  margin-bottom: 1rem;

  @media (max-width: 494px) {
    flex-direction: column;
  }
`;

const Stat = styled.div`
  margin-right: 20px;
`;

const StatNumber = styled.span`
  font-weight: bold;

  @media (max-width: 481px) {
    font-size: 14px;
  }
`;

const Bio = styled.p`
  font-size: 16px;
  margin-top: 0;
`;

const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
`;

const PostsContainerWithFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
`;



const WISHLISTED_TRIPS = "Wishlisted Trips";
const YOUR_COMMUNITIES = "Your communities";
const COMING_TRIPS = "Coming Trips"
const PAST_TRIPS = "Past Trips"

const ProfilePage = () => {
  const params = useParams();

  const username = params.username || "";

  const [tripsList, setTripsList] = useState([]);
  const [followedCommunities, setFollowedCommunities] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [activeSection, setActiveSection] = useState(WISHLISTED_TRIPS);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [dateRanges, setDateRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleOpenDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  const handleDateRangeChange = (ranges) => {
    setDateRanges([ranges.selection]);
  };

  const isUserHost = true;
  const isUserPublic = username.length > 0;


  useEffect(() => {
    let url = isUserPublic ? FETCH_PUBLIC_USER_PROFILE + username : FETCH_PRIVATE_USER_PROFILE
    apiHelper({ url })
      .then((response) => {
        setProfileData(response.data)

      })
      .catch((error) => {
        // alert("something went wrong, we are looking into it");
      });
  }, []);


  useEffect(() => {
    let url = FETCH_HOME_FEED_TRIPS
    if (!isUserPublic) {
      url = FETCH_USER_WISHLISTED_TRIPS
    } else {
      url = activeSection == COMING_TRIPS ? FETCH_HOME_FEED_TRIPS : FETCH_HOME_FEED_TRIPS
    }
    apiHelper({ url })
      .then((response) => {
        setTripsList(response.data)

      })
      .catch((error) => {
        // alert("something went wrong, we are looking into it");
      });
  }, [activeSection]);

  useEffect(() => {
    let url = FETCH_FOLLOWED_COMMUNITIES
    if (isUserPublic) {
      return

    }
    apiHelper({ url })
      .then((response) => {
        setFollowedCommunities(response.data)

      })
      .catch((error) => {
        // alert("something went wrong, we are looking into it");
      });
  }, [activeSection]);

  const PublicUserComponent = () => (
    <>
      <DateRangePicker
        ranges={dateRanges}
        onChange={handleDateRangeChange}
        isOpen={isDatePickerOpen}
        onClose={handleCloseDatePicker}
      />
      <PostsContainerWithFilter>
        <FilterButtonComponent onClick={handleOpenDatePicker} />
        <CenterContentContainer>
          <ToggleButton isActive={activeSection == COMING_TRIPS} onClick={() => setActiveSection(COMING_TRIPS)}>Coming Trips</ToggleButton>
          <ToggleButton isActive={activeSection == PAST_TRIPS} onClick={() => setActiveSection(PAST_TRIPS)}>Past Trips</ToggleButton>
        </CenterContentContainer>
        <PostsContainer>
          {tripsList.map((trip) => (
            <TripCardMini key={trip.id} tripDetails={trip} />
          ))}
        </PostsContainer>
      </PostsContainerWithFilter>
    </>
  );

  const PrivateProfileComponent = () => (
    <>
      <CenterContentContainer>
        <ToggleButton isActive={activeSection == WISHLISTED_TRIPS} onClick={() => setActiveSection(WISHLISTED_TRIPS)}>Wishlisted Trips</ToggleButton>
        <ToggleButton isActive={activeSection == YOUR_COMMUNITIES} onClick={() => setActiveSection(YOUR_COMMUNITIES)}>Your Communities</ToggleButton>
      </CenterContentContainer>
      <DateRangePicker
        ranges={dateRanges}
        onChange={handleDateRangeChange}
        isOpen={isDatePickerOpen}
        onClose={handleCloseDatePicker}
      />
      <PostsContainerWithFilter>
        {activeSection == WISHLISTED_TRIPS ? (<PostsContainer>
          {tripsList.map((trip) => (
            <TripCardMini key={trip.id} tripDetails={trip} />
          ))}
        </PostsContainer>) : (<>{followedCommunities.map((community) => (
          <StyledLink key={community.id}>
            <CommunityListingCard to={"/baigan/community/" + community.url}
              community={community}
            />
          </StyledLink>
        ))}</>)}

        <BeHostCard />
      </PostsContainerWithFilter>
    </>
  );

  return (
    <>
      <Navbar />
      <ProfileContainer>
        <NavBarPsuedo>
          <SideBar />
        </NavBarPsuedo>
        <Content>
          <Header>
            <ProfileImage src={profileData.image_url} alt="Profile" />
            <UserDetails>
              <UsernameContainer>
                <Username>{profileData.first_name} {profileData.last_name}</Username>
              </UsernameContainer>
              {isUserHost && (
                <Stats>
                  <Stat>
                    <StatNumber>71</StatNumber> posts
                  </Stat>
                  <Stat>
                    <StatNumber>{profileData.instagram_followers}</StatNumber> followers
                  </Stat>
                  <Stat>
                    <StatNumber>806</StatNumber> following
                  </Stat>
                </Stats>
              )}
              <Bio>{profileData.name}</Bio>
            </UserDetails>
          </Header>
          <BioSection>
            <BioText>Nigga i am beautiful</BioText>
          </BioSection>
          {isUserPublic ? <PublicUserComponent /> : <PrivateProfileComponent />}
        </Content>
      </ProfileContainer>
      <BottomBar />
    </>
  );
};

export default ProfilePage;
